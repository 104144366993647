.container {
    position: relative;
    z-index: 1;
    display: grid;
}
.layout {
    display: grid;
}

@media(max-width:599px) {

    .desktop {
        display: none;
    }

    .container {
        grid-template-columns: 1fr;
        overflow: hidden;
    }
    .layout {
        grid-template-columns: 1fr;
        grid-template-rows: auto 15rem;
    }
        .content {
            grid-area: 1/1 / 2/2;
            position: relative;
            z-index: 2;
            padding-right: 25vw;
        }    

    
}

@media(min-width:600px) {

    .mobile {
        display: none;
    }

    .container {
        grid-template-columns: repeat(2, 50%);
    }

    .offset {
        grid-area: 2/1 / 3/2;
        height: var(--layout-offset);
        margin-bottom: calc(var(--layout-offset) * -2);
    }


    .layout {
        grid-area: 1/1 / 2/3;
        grid-template-columns: repeat(2, 50%);
    }

        .content {
            grid-area: 1/1 / 2/2;
            padding-bottom: 0;
            z-index: 2;
        }

}







/* THEME 2025 */


.swoosh {
    grid-area: 2/1 / 3/2;    
    position: relative;
    /* background-color: #0082b1; */
    overflow: hidden;
}

.swooshContainer {
    position: absolute;
    top: -40%;
    right: -10%;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    transform: rotate(60deg) scale(2);
}

@media(min-width:600px) {
    .swoosh {
        grid-area: 1/1 / 2/3;    
        aspect-ratio: 2/1;
    }
    .swooshContainer {
        position: absolute;
        top: -50%;
        right: -25%;
        width: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        transform: rotate(-40deg) scale(1.25);
    }
}



.swooshLayer {
    position: absolute;
}


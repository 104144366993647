.layout {
    display: grid;
    background-color: var(--light);
    width: 100%;
    overflow: hidden;
}

.quote {
    grid-row-start: 3;
}

.impactGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-md);
    margin-top: var(--space-md);
}

.quote,
.impact {
    padding: var(--space-md);
}

@media (min-width:800px) {
    .layout {
        grid-template-columns: repeat(2, 1fr);
    }

    .graphic {
        justify-content: flex-end;
    }

    .quote {
        grid-area: 2/1 / 2/2;
    }

    .impact {
        grid-area: 1/2 / 3/3;
    }

    .quote,
    .impact {
        padding: var(--space-lg);
    }
}
@media (max-width:599px) {

    .desktop {
        display: none;
    }

    .layout {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 6rem auto;
    }
    .quoteContainer {
        grid-area: 1/1 / 3/2;
        z-index: 2;
    }
    .graphicContainer {
        grid-area: 2/1 / 4/2;
        position: relative;
        overflow: hidden;
        height: 50vw;
    }
    

}

@media(min-width:600px) {

    .mobile {
        display: none;
    }

    .layout {
        display: grid;
        grid-template-columns: 3fr 2fr;
        padding-bottom: 4rem;
    }
    .quoteContainer {
        margin: 4rem 0;
    }
    .graphicContainer {
        position: relative;
        margin-bottom: -4rem;
        overflow: hidden;
    }
    .graphicContainer img {
        height: 100%;
    }


}


.gridLayout {
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    overflow: hidden;
}

/* image */

.imageContainer img {
    display: block;
}

.spacer {
    position: relative;
}


.iconBox {
    width: 5rem;
    height: 5rem;
    border: 1px solid currentColor;
    border-radius: var(--default-radius);

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.icon {
    fill: white;
    width: 60%;
}


.montageGraphicBottom {
    display: none;
}


@media (max-width:599px) {
    .spacer {
        display: none;
    }
    .imageContainer {
        margin: var(--space-xs) var(--space-md);
    }
    .imageContainer:not(:last-child) {
        margin-bottom: 0;
    }

    .montageGraphicTop {
        grid-row-start: 1;
        display: block;
        position: relative;
        height: 30vw;
        background-color: white;
        overflow: hidden;
    }
    
        .graphicMain {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
        }
        .graphicSecondary {
            display: none;
        }
}


@media (min-width:600px) {
    .gridLayout {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .double,
    img.double {
        grid-column: span 2;
    }
    
}
@media (min-width:800px) {
    .gridLayout {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .montageGraphicTop {
        display: block;
        grid-column: span 2;
        position: relative;
        background-color: white;
        overflow: hidden;
    }
    .montageGraphicBottom {
        display: block;
        grid-column: span 2;
        position: relative;
        background-color: white;
        overflow: hidden;
    }
    
        .graphicMain {
            position: absolute;
            top: 12rem;
            left: 0;
            width: 100%;
        }
        .graphicSecondary {
            position: absolute;
            top: 20%;
            
            right: -30%;
            animation: rotate 40s infinite linear;
            mix-blend-mode: hard-light;
            filter: hue-rotate(15deg) brightness(1.15) saturate(.8);
        }

}


.background {
    min-height: 100vh;
    background: rgb(1,32,126);
    background: linear-gradient(128deg, rgba(1,32,126,1) 0%, rgba(61,151,181,1) 50%, rgba(89,101,209,1) 100%);

    padding-bottom: var(--space-lg);
    color: white;
}

.header {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.brandBlock {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    margin: var(--space-md);
}
@media(min-width:900px) {
    .brandBlock {
        grid-template-columns: 12vw auto;
    }
}



.actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--space-md);
}
.actions img {
    cursor: pointer;
    width: 30vw;
}
@media(min-width:900px) {
    .actions {
        position: relative;
    }
    .actions img {
        width: 15vw;
    }
}



.logo {
    width: 20vw;
    margin-bottom: var(--space-xs);
}
@media(min-width:900px) {
    .logo {
        width: 80%;
    }
}


.bannerImage {
    position: relative;
    z-index: 2;
    width: 100%;
    aspect-ratio: 1920/667;
    overflow: hidden;
    background: black;
}
    .bannerImage img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
@media(max-width:899px){
    .bannerImage {
        height: 16rem;
    }
}




.eventTheme {
    position: relative;
    z-index: 2;
    padding: var(--space-md) var(--tacf-margin);
}
    .eventThemeTitle {
        font-size: 10vw;
        text-transform: uppercase;
        line-height: 1;
    }

    @media(min-width:900px) {
        .eventThemeTitle {
            font-size: 5.5vw;
        }
    }



@media(min-width:900px) {
    .columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.box01, .box02 {
    position: relative;
    z-index: 3;
    background-color: var(--tacf-dark);
    padding: var(--space-md) var(--space-md) var(--space-md) var(--tacf-margin);
}

.box01 {
    margin-bottom: calc( var(--space-md) * -1 );
}
@media(min-width:900px) {
    .box01 {
        margin-bottom: calc( var(--space-md) * -1 );
        width: 50%;
    }
}

.box02 {
    margin-top: calc( var(--space-md) * -1 );
}

.box03 {
    position: relative;
    z-index: 3;
    background-color: white;
    padding: var(--space-md) var(--tacf-margin) var(--space-md) var(--space-md);
    color: rgb(64, 64, 64);
}

.partnerHeading {
    margin-top: var(--space-sm);
    font-weight: 500;
    text-transform: uppercase;
}
.partnerGrid {
    display: inline-grid;
    grid-template-columns: 2.25fr 2.5fr 2fr;
    gap: var(--space-sm);
    width: 60vw;
    margin-top: var(--space-xs);
}
.partnerLogo {
    width: 100%;
}

@media(min-width:900px){
    .partnerGrid {
        width: 25vw;
    }
}


.callout {
    padding: var(--space-md) var(--space-md) var(--space-md) var(--tacf-margin);
}
    .callout a {
        text-decoration: none;
    }






/* SQUIGGLES ------------------ */

.bgSquiggle {
    position: absolute;
    top: 26vh;
    right: 0;
    width: 80%;
    overflow: hidden;
}
    .bgSquiggle img {
        width: 125%;
    }

@media(min-width:900px) {
    .bgSquiggle {
        top: var(--space-lg);
        width: 50%;
    }
}


.sideSquiggle {
    position: absolute;
    top: 5vw;
    right: 0;
    width: 10vw;
}
    .sideSquiggle img {
        width: 100%;
    }

@media(max-width:899px) {
    .sideSquiggle {
        display: none;
    }
}




/* banner ---------------------------------- */

.banner {
    position: relative;
    background: rgb(1,32,126);
    background: linear-gradient(128deg, rgba(1,32,126,1) 0%, rgba(61,151,181,1) 50%, rgba(89,101,209,1) 100%);
    overflow: hidden;

    padding: var(--space-md);

    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: var(--space-md);

    color: white;
}
.bannerContent,
.bannerBrandBlock {
    z-index: 2;
}

.bannerBrandBlock {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}
.bannerSquiggleContainer {
    position: absolute;
    left: -30vw;
}
.bannerSquiggle {
    width: 180%;
}

@media(min-width:900px) {
    .bannerBrandBlock {
        grid-template-columns: 12vw auto;
    }
    .banner {
        grid-template-columns: repeat(2, 1fr);
    }
    .bannerSquiggleContainer {
        left: -5vw;
    }
    .bannerSquiggle {
        width: 60%;
    }
}



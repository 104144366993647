.overlay {
    position: fixed;
    z-index: 250;
    inset: 0;
    background-color: rgb(51, 51, 51);
    
    padding: var(--space-sm);

    opacity: 0;
    pointer-events: none;

    transition: opacity 350ms ease-in-out;
}

.overlay.active {
    opacity: 1;
    pointer-events: all;
}

.content {
    position: relative;
    background-color: var(--light);
}

.closeButton {
    cursor: pointer;
    position: fixed;
    z-index: 10;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border: 0;
    line-height: 1;
    background: transparent;
    font-size: 2rem;
    color: white;
    opacity: .5;

    transition: all 200ms ease-in-out;
}
.closeButton:hover {
    opacity: 1;
}
.layout {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    overflow: hidden;
    
}

.hero {
    grid-area: 1/1 / 2/2;
    position: relative;
    aspect-ratio: 5/3;
}
.hero img {
    object-fit: cover;
}

.content {
    grid-area: 2/1 / 3/2;
    background-color: var(--dark);
    padding: var(--space-md);
}


@media (max-width: 799px) {
    .filler {
        display: none;
    }
        .ringOne {
            position: absolute;
            bottom: 0;
            right: -40%;
            animation: rotate 40s infinite linear;
        }
}

@media ( min-width: 800px) {
    .hero {
        grid-area: 2/1 / 4/2;
        position: relative;
        aspect-ratio: 5/3;
    }

    .content {
        grid-area: 1/2 / 3/2;
        padding: var(--space-lg);
    }
    
    .filler {
        grid-area: 3/2 / 4/2;
        background-color: var(--light);
    }

    .layout {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: var(--space-lg) auto 10vw;
        margin-top: calc(var(--space-lg) * -1);
    }

        .ringOne {
            position: absolute;
            bottom: 0;
            right: -40%;
            animation: rotate 40s infinite linear;
        }
}




@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}
.swoosh {
    position: relative;
    background-color: #0082b1;
    overflow: hidden;

    aspect-ratio: 2/1;
}

.swooshContainer {
    position: absolute;
    top: -50%;
    right: -25%;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;

    /* border: 1px magenta solid; */
    transform: rotate(-40deg) scale(1.25);
}

.swooshLayer {
    position: absolute;
}




.strapline p {
    font-family: 'outfit';
    font-size: 10vw;
    font-weight: 400;
    line-height: 1;
    color: white;
    text-transform: uppercase;
}

@media(min-width:600px) {
    .strapline p {
        font-size: 5vw;
    }
}
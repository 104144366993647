.avatar {
    display: inline-block;
    position: relative;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgb(212, 212, 212);
}
.avatar img {
    object-fit: cover;
    object-position: top center;
    width: 100%;
    height: 100%;
}
.layout {
    max-width: 14rem;
}

.heading {
    padding-bottom: 1rem;
    text-align: center;
}
.slider {
    text-align: center;
    border: 1px dashed var(--primary);
    border-top: none;
    border-bottom: none;
    background-color: white;
}

@media(min-width:800px) {
    .layout {
        max-width: 18rem;
    }
}




/* logo layout --------------------------------------- */

.logo {
    display: inline-block;
    position: relative;
    width: 8rem;
    aspect-ratio: 1.2;
}
.logoWrapper {
    position: absolute;
    inset: 0;
}
.logo img {
    display: block;
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
}

@media(min-width:800px) {
    .logo {
        width: 12rem;
    }
}
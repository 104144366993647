
.logos {
    padding: var(--space-lg) var(--space-md);
}

@media(max-width:899px){   
    .headingContainer {
        position: relative;
        padding: var(--space-md);
        overflow: hidden;
    }
    .heading {
        flex: 1;
        /* padding: var(--space-lg); */
    }

        .ringOne {
            position: absolute;
            top: 1rem;
            right: -13rem;
            width: 25rem;
            animation: rotate 40s infinite linear;
        }
        .ringTwo {
            position: absolute;
            bottom: -20%;
            right: 40%;
            left: -40%;
            animation: rotate 50s infinite linear;
            mix-blend-mode: hard-light;
            filter: hue-rotate(15deg) brightness(.9) saturate(1.5);
        }
}

@media(min-width:900px){
    .layout {
        display: grid;
        grid-template-columns: 1fr 2.5fr;
    }
    .headingContainer {
        grid-area: 1/1 / 4/2;
        position: relative;
        text-align: right;
        display: flex;
        flex-direction: column;
    }
    .heading {
        flex: 1;
        position: relative;
        padding: var(--space-lg);
        overflow: hidden;
    }

        .ringOne {
            position: absolute;
            bottom: -30%;
            right: -30rem;
            width: 50rem;
            animation: rotate 40s infinite linear;
        }
        .ringTwo {
            position: absolute;
            bottom: -20%;
            right: 40%;
            left: -40%;
            animation: rotate 50s infinite linear;
            mix-blend-mode: screen;
            filter: hue-rotate(15deg) brightness(.9) saturate(1.5);
        }
        

    .tab {
        height: var(--layout-offset);
        background-color: var(--light);
    }
    .logos {
        grid-area: 1/2 / 3/3;
        padding: var(--space-lg) var(--space-md);
        justify-content: flex-start;
    }
}




@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}
.openingQuote,
.closingQuote {
    fill: currentColor;
}

@media(max-width:799px) {
    .openingQuote {
        display: block;
        width: 1rem;
        height: 1rem;
        margin-bottom: .5rem;
    }

    .closingQuote {
        height: .5rem;
        margin-left: .2rem;
        margin-bottom: 1.35rem;
    }
}
@media(min-width:800px) {
    .openingQuote {
        width: 1rem;
        height: 1rem;
        margin-right: 1rem;
        margin-left: -2rem;
        margin-bottom: .8rem;
    }

    .closingQuote {
        height: .5rem;
        margin-left: .2rem;
        margin-bottom: 1.35rem;
    }
}
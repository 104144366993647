.layout {
    width: 100%;
    overflow: hidden;
}

.slider {
    display: grid;
    grid-template-columns: 100%;
    gap: var(--space-lg);
    padding: var(--space-md);
}

.heading {
    padding: var(--space-md) var(--space-md) 0;
}



.sliderButton {
    background-color: var(--accent);
    width: 3.6rem;
    height: 3.6rem;
}
.sliderButton svg {
    width: 1.4rem;
    height: 1.4rem;
}
.prev {
    left: -1.8rem;
}
.next {
    right: -1.8rem;
}



@media(min-width:800px) {

    .layout {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .graphics {
        grid-area: 1/2 / 2/3;
        background-color: var(--wash);
    }

    .heading {
        grid-area: 1/1 / 2/2;
        align-self: flex-end;
        padding: var(--space-lg) 0 0 var(--space-lg);
    }
    
    .slider {
        grid-area: 2/1 / 3/3;
        grid-template-columns: 25% 75%;
        margin-right: var(--space-lg);
        padding: var(--space-md) var(--space-lg) var(--space-lg);
    }    
}




